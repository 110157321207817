.pg-forgot-pass {
  background: #ededed;
  padding: 3%;
}
.pg-forgot-pass .form {
  position: relative;
  width: 900px;
  /* height: 445px; */
  margin: auto;
  background: #fff;
  padding: 50px;
}
.pg-forgot-pass .form h2 {
  text-align: center;
}
.pg-forgot-pass .form label {
  display: block;
  width: 260px;
  margin: 25px auto 0 !important;
  text-align: center;
}
.pg-forgot-pass .form label span {
  font-size: 12px;
  color: #cfcfcf;
  text-transform: uppercase;
}
.pg-forgot-pass .form label input {
  display: block;
  width: 100%;
  margin-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  text-align: center;
}
.pg-forgot-pass .forgot-sendbtn {
  background: #d4af7a;
  text-transform: uppercase;
  display: block;
  margin: 20px auto !important;
  width: 260px;
  height: 36px;
  border-radius: 30px;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
}
.forgotPass-or {
  padding: 1% 0;
  width: 260px;
  margin: auto;
}
.forgotPass-or p {
  margin-bottom: 0px;
  color: #9f9292;
  text-align: center;
  margin-top: 15px;
}
.forgotPass-or .or-block {
  position: relative;
}
.forgotPass-or .or-block p {
  position: absolute;
  top: -25px;
  text-align: center;
  left: 42%;
  background-color: #fff;
  padding: 0px 10px;
  z-index: 9;
}
.forgotPass-or .or-block hr {
  position: relative;
}
.forgotPass .sign-up label {
  display: block !important;
  width: 260px;
  margin: 25px auto 0 !important;
  text-align: center;
}
.signup-btn {
  background: #d4af7a;
  text-transform: uppercase;
  display: block;
  margin: 20px auto !important;
  width: 260px;
  height: 36px;
  border-radius: 30px;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .pg-forgot-pass .form {
    width: 100%;
  }
}
