/* Features */
.pg-marathon section.ev-Banner_sec {
  padding: 3% 8% 8vh 8%;
}
.pg-marathon section.ev-Banner_sec {
  height: 70vh;
}
.pg-marathon section.ev-features {
  padding: 22vh 0% 12vh 0%;
  background: url(/public/images/Marathon/features_bg.svg);
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}
.pg-marathon section.ev-features h2 {
  font-size: 30px;
  font-family: "Poppins";
  font-weight: 400;
  color: #2d3745;
}
.pg-marathon section.ev-features ul {
  padding: 3% 0 0 4%;
}
.pg-marathon section.ev-features ul li {
  list-style-image: url(/public/images/Marathon/SVG_Circle.svg);
  font-size: 14px;
  color: #2d3745;
  margin-bottom: 1%;
}
.pg-marathon section.ev-features ul li::marker {
  font-size: 11px;
  line-height: 9px;
}
.pg-marathon section.ev-features .right_sec {
  padding: 0 5% 0 15%;
}
.pg-marathon section.ev-features .left_sec {
  padding: 0 5% 0 15%;
}

/* Invest */
.pg-marathon section.ev-invest h2.main_head {
  margin-top: 8%;
  font-size: 32px;
  font-family: "Poppins";
  font-weight: 500;
}
.pg-marathon section.ev-invest {
  font-size: 75px;
  font-family: sans-serif;
  font-weight: bold;
  text-align: center;
  background: transparent;
}

/* Apply */

.pg-marathon section.ev-apply {
  margin: 15vh 0;
}

.pg-marathon section.ev-apply h2.main_head {
  font-family: "Poppins";
  font-size: 35px;
  font-weight: 500;
  line-height: 35px;
  width: 100%;
  margin-bottom: 5%;
  color: #000;
}
.pg-marathon section.ev-apply .left_sec h3 {
  font-size: 25px;
  font-family: "Poppins";
  font-weight: 400;
  color: #000;
}
.pg-marathon section.ev-apply .left_sec ul li {
  font-size: 13px;
  color: #2d3745;
  margin-bottom: 1%;
  list-style-image: url(/public/images/Marathon/SVG_Circle.svg);
}
.pg-marathon section.ev-apply .left_sec ul {
  padding-left: 4%;
  margin: 2% 0 5% 0;
}
.pg-marathon button.green_btn {
  background: #41ac3e;
  width: 100%;
  margin: 4% auto 0 auto;
  padding: 1% 2%;
  height: 7vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: none;
  border-radius: 0.5vw;
  cursor: pointer;
}
.pg-marathon section.ev-apply button.green_btn {
  width: 40%;
}
.pg-marathon section.ev-Banner_sec p {
  font-size: 24px;
  margin-bottom: 7%;
}
.pg-marathon section.ev-Banner_sec p e {
  color: #41ac3e;
  font-weight: 300;
}
.pg-marathon section.ev-Banner_sec p span {
  color: #41ac3e;
  font-weight: 300;
}
.pg-marathon section.ev-Banner_sec h1 {
  width: 100%;
}
.pg-marathon section.ev-Banner_sec h1 span {
  color: #41ac3e;
}
.pg-marathon section.ev-Banner_sec {
  padding: 3% 8% 8vh 8%;
}
.pg-marathon section.ev-Banner_sec > .flexi > .coverup {
  justify-content: center;
}
.pg-marathon section.ev-Banner_sec a {
  width: 70%;
}
body.ev-home.marathon_page .pg-marathon section.ev-about-sec {
  background: none;
}
.ev-about-sec .about-left_sec h2.main_head {
  font-family: "Poppins";
  font-size: 35px;
  font-weight: 500;
  line-height: 35px;
}

/* Mobile View */

@media screen and (max-width: 480px) {
  .pg-marathon .about-left_sec .inner_wrapper {
    text-align: center;
  }
  .pg-marathon section.ev-about-sec {
    margin: 7vh 0;
  }
  .pg-marathon .about-right_sec .about_img {
    width: 95%;
    margin: auto;
    display: block;
  }
  .pg-marathon .about-right_sec .about_img::before {
    right: -4%;
    bottom: -4%;
  }
  .pg-marathon section.ev-features {
    padding: 12vh 0% 12vh 0%;
  }
  .pg-marathon section.ev-apply {
    margin: 10vh 2vh;
  }
  .pg-marathon section.ev-apply button.green_btn {
    width: 85%;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}
/* 
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically 
  display: flex;
  justify-content: center;
  align-items: center;
} */

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-slide {
  /* height: 10vh !important; */
}
.swiper-slide {
  padding: 2em;
  border: 1px solid #ccc;
}
