.blog-list p {
  margin-bottom: 0px !important;
}

.blog-list {
  display: flex;
  flex-direction: row;
  font-family: "Poppins";
  flex-wrap: wrap;
}
.blog-list .blog-item {
  flex: 30% 0;
  margin: 20px 10px;
  /* -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.64);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.64);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.64);
  border-radius: 10px; */
}
.blog-list .blog-item img {
  height: 150px;
}
.blog-list .blog-item img {
  width: 100%;
}
.blog-list .blog-item .sub-text {
  padding: 5px 0px;
  font-size: 14px;
  color: #41ac3e;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.blog-list .blog-item .blog-head {
  /* background-color: #b6d5ce; */
  font-size: 18px;
  padding: 0px 0px;
  font-family: "Poppins";
  text-align: left;
}
.blog-list .blog-item .blog-info {
  font-size: 12px;
  padding: 5px 0px;
  color: #828282;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: "Poppins";
}
.blog-content .head-cto {
  margin: 5px 0px;
  background: #41ac3e;
  border: none;
  color: #fff;
  width: 8vw;
  height: 5vh;
  /* border-radius: 0.5vw; */
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 400;
}
.blog-content .head-cto img {
  width: 6px;
}
