*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
/* #root {
  background: #ededed;
} */
body {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

input,
button {
  outline: none;
  background: none;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

button {
  border: none;
}
.pg-login {
  background: #ededed;
  padding: 3%;
}
.pg-login .tip {
  font-size: 20px;
  margin: 40px auto 50px;
  text-align: center;
}

.pg-login .cont {
  overflow: hidden;
  position: relative;
  width: 900px;
  height: 445px;
  margin: auto;
  background: #fff;
}

.pg-login .form {
  position: relative;
  width: 640px;
  height: 100%;
  transition: transform 1.2s ease-in-out;
  padding: 50px 30px 0;
}

.pg-login .sub-cont {
  overflow: hidden;
  position: absolute;
  left: 640px;
  top: 0;
  width: 900px;
  height: 100%;
  padding-left: 260px;
  background: #fff;
  transition: transform 1.2s ease-in-out;
}
.pg-login .cont.s--signup .sub-cont {
  transform: translate3d(-640px, 0, 0);
}

.pg-login .sign-in button,
.pg-login .sign-up button {
  display: block;
  margin: 0 auto;
  width: 260px;
  height: 36px;
  border-radius: 30px;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
}

.pg-login .img {
  overflow: hidden;
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  width: 260px;
  height: 100%;
  padding-top: 360px;
}
.pg-login .img:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 900px;
  height: 100%;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/sections-3.jpg");
  background-size: cover;
  transition: transform 1.2s ease-in-out;
}
.pg-login .img:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.pg-login .cont.s--signup .img:before {
  transform: translate3d(640px, 0, 0);
}
.pg-login .img__text {
  z-index: 2;
  position: absolute;
  left: 0;
  top: 50px;
  width: 100%;
  padding: 0 20px;
  text-align: center;
  color: #fff;
  transition: transform 1.2s ease-in-out;
}
.pg-login .img__text h2 {
  margin-bottom: 10px;
  font-weight: normal;
}
.pg-login .img__text p {
  font-size: 14px;
  line-height: 1.5;
}
.pg-login .cont.s--signup .img__text.m--up {
  transform: translateX(520px);
}
.img__text.m--in {
  transform: translateX(-520px);
}
.pg-login .cont.s--signup .img__text.m--in {
  transform: translateX(0);
}
.pg-login .img__btn {
  overflow: hidden;
  z-index: 2;
  position: relative;
  width: 100px;
  height: 36px;
  margin: 0 auto;
  background: transparent;
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  cursor: pointer;
}
.pg-login .img__btn:after {
  content: "";
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #fff;
  border-radius: 30px;
}
.pg-login .img__btn span {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: transform 1.2s;
}
.pg-login .img__btn span.m--in {
  transform: translateY(-72px);
}
.pg-login .cont.s--signup .img__btn span.m--in {
  transform: translateY(0);
}
.pg-login .cont.s--signup .img__btn span.m--up {
  transform: translateY(72px);
}

.pg-login h2 {
  width: 100%;
  font-size: 26px;
  text-align: center;
}

.pg-login .sign-in label {
  display: block !important;
  width: 260px;
  margin: 25px auto 0 !important;
  text-align: center;
}
.pg-login label span {
  font-size: 16px;
  color: #cfcfcf;
  text-transform: uppercase;
}

.pg-login input {
  display: block;
  width: 100%;
  margin-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  text-align: center;
}

.pg-login .forgot-pass {
  margin-top: 15px;
  text-align: center;
  font-size: 12px;
  color: #cfcfcf;
}

.pg-login .submit {
  margin-top: 40px !important;
  margin-bottom: 20px !important;
  background: #d4af7a;
  text-transform: uppercase;
}

.pg-login .fb-btn {
  border: 2px solid #d3dae9 !important;
  color: #8fa1c7 !important;
}
.pg-login .fb-btn span {
  font-weight: bold;
  color: #455a81;
}

.pg-login .sign-in {
  transition-timing-function: ease-out;
}
.pg-login .cont.s--signup .sign-in {
  transition-timing-function: ease-in-out;
  transition-duration: 1.2s;
  transform: translate3d(640px, 0, 0);
}

.pg-login .sign-up {
  transform: translate3d(-900px, 0, 0);
}
.pg-login .cont.s--signup .sign-up {
  transform: translate3d(0, 0, 0);
}
.pg-login .sign-up {
  display: flex;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  padding-bottom: 20px;
  flex-wrap: wrap;
}
.pg-login .sign-up label {
  width: 45%;
  margin: auto;
}
.pg-login .icon-link {
  position: absolute;
  left: 5px;
  bottom: 5px;
  width: 32px;
}
.pg-login .icon-link img {
  width: 100%;
  vertical-align: top;
}
.pg-login .icon-link--twitter {
  left: auto;
  right: 5px;
}
.login-mobile {
  display: none;
}

/* Mobile View */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 480px) {
  .pg-login .forgot-pass {
    position: relative;
    top: 10px;
    font-size: 14px;
  }
  .pg-login .cont {
    display: none;
  }
  .login-mobile {
    display: block;
  }
  .login-mobile .form {
    width: 100%;
    background-color: #fff;
    padding: 50px 30px;
    transition-delay: 1s;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
    text-align: center;
  }
  .login-mobile .sign-up {
    display: none;
    transition-delay: 1s;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }
  .signupAdd .sign-up {
    display: block;
  }
  .signupAdd .sign-in {
    display: none;
  }
  .pg-login .sign-up {
    transform: translate3d(00px, 0, 0);
  }
  .mobileview-btn {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    background: #d4af7a;
    text-transform: uppercase;
    display: block;
    margin: 0 auto;
    width: 260px;
    height: 36px;
    border-radius: 30px;
    color: #fff;
    font-size: 15px;
    cursor: pointer;
  }
  .login-mobile-or {
    padding: 5% 0;
  }
  .login-mobile-or p {
    margin-top: 20px;
    color: #9f9292;
    text-align: center;
  }
  .login-mobile-or .or-block {
    position: relative;
  }
  .login-mobile-or .or-block p {
    position: absolute;
    top: -33px;
    text-align: center;
    left: 44%;
    background-color: #fff;
    padding: 0px 10px;
    z-index: 9;
  }
  .login-mobile-or .or-block hr {
    position: relative;
  }
  .login-mobile .sign-up label {
    display: block !important;
    width: 260px;
    margin: 25px auto 0 !important;
    text-align: center;
  }
}
