.pg-contactus section.contact_page {
  margin: 15vh 0;
}
.pg-contactus section.ev-Banner_sec {
  height: 50vh !important;
}
.pg-contactus section.contact_page .right_aside-contact_form h3 {
  font-size: 34px;
  font-family: "Poppins";
  font-weight: 500;
  color: #2d3745;
  line-height: 29px;
  text-align: left;
}

.pg-contactus .left_aside-contact ul {
  width: 80%;
  padding-left: 0px;
}
.pg-contactus .left_aside-contact ul li a {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
}
.pg-contactus .left_aside-contact ul li a img {
  width: 15px;
  height: 15px;
  object-fit: contain;
  margin-right: 10px;
}
.pg-contactus .left_aside-contact ul li {
  margin-bottom: 4%;
}
.pg-contactus .left_aside-contact ul li:last-child {
  margin-bottom: 0%;
}
.pg-contactus section.contact_page .right_aside-contact_form form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 3%;
}
.pg-contactus section.contact_page .right_aside-contact_form form input,
.pg-contactus section.contact_page .right_aside-contact_form form select {
  border: none;
  width: 48%;
  background: #f2f2f2;
  margin: 4px;
  padding: 10px 16px;
  box-sizing: border-box;
  border-radius: 4px;
}
.pg-contactus section.contact_page .right_aside-contact_form form textarea {
  border: none;
  width: 99%;
  background: #f2f2f2;
  padding: 10px 16px;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 4px;
  height: 16vh;
}

.owl-carousel .owl-stage:after {
  content: none !important;
}
.pg-contactus section.our_partners .slide_box .owl-wrapper {
  display: flex;
  width: 100%;
}
.pg-contactus section.ev-news_sec .no-js .owl-carousel,
.owl-carousel.owl-loaded {
  display: grid !important;
}
.no-js .owl-carousel,
.owl-carousel.owl-loaded {
  display: grid !important;
}

.pg-contactus .map_view iframe {
  width: 90%;
}
.pg-contactus .map_view {
  margin-top: 5%;
}
.pg-contactus button.green_btn {
  background: #41ac3e;
  width: 100%;
  margin: 4% auto 0 auto;
  padding: 1% 2%;
  height: 7vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: none;
  border-radius: 0.5vw;
  cursor: pointer;
}
