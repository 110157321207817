.pg-partnership .ev-Banner_sec {
  height: 50vh !important;
}
.pg-partnership button.line-btn {
  background: transparent;
  width: 170px;
  margin: 4% auto 0 0;
  padding: 1% 2%;
  height: 6vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 0.5vw;
  cursor: pointer;
}

.pg-partnership section.ev-philosophy_sec {
  height: 45vh;
  position: relative;
}
.pg-partnership section.ev-philosophy_sec::after {
  position: absolute;
  display: flex;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(/public/images/Partnership/about_bg.svg);
  background-size: revert;
  background-repeat: no-repeat;
  z-index: -1;
  transform: scaleX(-1);
}
body.ev-home.page_about.partnership .about-left_sec h3 {
  font-size: 25px;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 30px;
}
.pg-partnership section.our_partners {
  margin-bottom: 14vh;
}
.pg-partnership section.our_partners .item {
  display: grid;
}
.pg-partnership section.our_partners .item h3 {
  color: #2d3745;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  margin-top: 4%;
}
.pg-partnership section.our_partners .slide_box {
  margin-top: 4%;
}

.pg-partnership section.our_partners .slide_box {
  width: 100%;
  display: flex;
  margin-top: 5%;
}
.pg-partnership section.our_partners h3 {
  font-size: 42px;
  font-family: "Poppins";
  font-weight: 400;
  width: 100%;
}
.pg-partnership section.our_partners p.para {
  color: #2d3745;
  font-size: 14px;
}
.pg-partnership section.our_partners .slick-slider .item img {
  border-radius: 10px;
  height: 15vh;
}
.pg-partnership section.partner_form-sec {
  /* margin-bottom: 15vh; */
}
.pg-partnership section.partner_form-sec form.form_sec h2 {
  font-size: 36px;
  font-family: "Poppins";
  font-weight: 400;
  margin-bottom: 5%;
}
.pg-partnership section.partner_form-sec form.form_sec input {
  width: 44%;
  float: left;
  margin-right: 1%;
  margin-bottom: 1%;
  border: 1px solid #bbe9ba;
  padding: 1% 2%;
  color: #181818;
}
.pg-partnership .button {
  display: flex;
  flex: 100% 0 1;
  width: 26%;
}
.pg-partnership .button button.green_btn {
  justify-content: space-around;
  height: 6vh;
  margin-top: 0vh;
}
.pg-partnership section.partner_form-sec .img_sec {
  display: flex;
  justify-content: flex-end;
}
.pg-partnership section.partner_form-sec .img_sec img {
  max-width: 70%;
}
/* Benefits */

.pg-partnership section.ev-philosophy_sec {
  margin: 25vh 0;
}
.pg-partnership section.ev-philosophy_sec h3 {
  font-size: 42px;
  font-family: "Poppins";
  font-weight: 400;
}
.pg-partnership section.ev-philosophy_sec p {
  color: #2d3745;
  font-size: 14px;
  margin-top: 8px;
}
.pg-partnership section.ev-philosophy_sec ul {
  display: flex;
  margin: 2% 0 0 0;
  padding-left: 0px;
}
.pg-partnership section.ev-philosophy_sec ul li {
  width: 20%;
  padding: 1.2%;
}
.pg-partnership section.ev-philosophy_sec ul li a {
  display: grid;
}
.pg-partnership section.ev-philosophy_sec ul li a h5 {
  font-size: 22px;
  font-family: "Poppins";
  font-weight: 400;
}
.pg-partnership section.ev-philosophy_sec ul li a p {
  color: #2d3745;
  font-size: 12px;
  margin-top: 4px;
}
.pg-partnership section.ev-philosophy_sec ul li a img {
  width: 54px;
  height: 54px;
  margin-bottom: 6px;
}
.pg-partnership section.ev-philosophy_sec {
  height: 45vh;
  position: relative;
}
.pg-partnership section.ev-philosophy_sec::after {
  position: absolute;
  display: flex;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(/public/images/Partnership/about_bg.svg);
  background-size: revert;
  background-repeat: no-repeat;
  z-index: -1;
  transform: scaleX(-1);
}
/* Partner Form */

.pg-partnership section.partner_form-sec {
  /* margin-bottom: 15vh; */
}
.pg-partnership section.partner_form-sec form.form_sec h2 {
  font-size: 36px;
  font-family: "Poppins";
  font-weight: 400;
  margin-bottom: 5%;
}
.pg-partnership section.partner_form-sec form.form_sec input {
  width: 80%;
  /* float: left; */
  margin-right: 1%;
  margin-bottom: 3%;
  border: 1px solid #bbe9ba;
  padding: 1% 2%;
  color: #181818;
}
.pg-partnership section.partner_form-sec form.form_sec .pt-lf {
  float: right;
}
.pg-partnership section.partner_form-sec form.form_sec .ptrt {
  float: left;
}
.pg-partnership section.partner_form-sec .img_sec {
  display: flex;
  justify-content: flex-end;
}
.pg-partnership section.partner_form-sec .img_sec img {
  max-width: 70%;
}

.pg-partnership section.ev-about-sec .about-left_sec .inner_wrapper h3 {
  font-family: "Poppins";
}

.pg-partnership.button button.green_btn {
  justify-content: space-around;
  height: 6vh;
  margin-top: 5vh;
}
.pg-partnership button.green_btn {
  background: #41ac3e;
  width: 100%;
  margin: 1% auto 0 auto;
  padding: 1% 2%;
  height: 7vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: none;
  border-radius: 0.5vw;
  cursor: pointer;
}

/*  */
.partner-us {
  /* background-color: #377547;  */
  padding: 50px 0px;
  position: relative;
  z-index: 1;
  background: linear-gradient(90deg, #141d3c, #41ac3e);
}
.partner-us h2 {
  text-align: center;
  color: #fff;
  font-family: "Poppins";
  margin-bottom: 30px;
}
.partner-us form input {
  background-color: #fff;
  border-radius: 5px;
  height: 6vh;
}
.partner-us .form_sec {
  /* display: flex; */
}
.partner-us img {
}
.partner-us .img_sec {
  justify-content: center !important;
}
.partner-us .img_sec img {
  position: relative;
  z-index: 2;
  opacity: 0.9;
  width: 100%;
  max-width: 100% !important;
  /* top: 25%; */
}
.partner-us-content {
  align-items: center;
}

/* Mobile view */
@media screen and (max-width: 480px) {
  .pg-partnership section.ev-Banner_sec {
    padding: 30% 8% 18vh 8%;
  }
  .about-left_sec .inner_wrapper {
    padding: 0% 5%;
    text-align: center;
  }
  .about-right_sec .about_img::before {
    right: -4%;
  }
  .pg-partnership section.ev-philosophy_sec ul {
    flex-wrap: wrap;
    justify-content: center;
    padding: 10% 0%;
  }
  .pg-partnership section.ev-philosophy_sec ul li {
    width: 50%;
  }
  .pg-partnership section.ev-philosophy_sec {
    height: 100%;
  }
  .pg-partnership section.ev-philosophy_sec ul li a img {
    display: block;
    margin: auto;
  }
  .pg-partnership section.ev-philosophy_sec ul li a {
    text-align: center;
  }
  .pg-partnership section.ev-philosophy_sec ul li a p {
    font-size: 14px;
    margin-top: 12px;
  }
  .partner-us .form_sec {
    display: flex;
    flex-direction: column;
    margin: 40px 0px;
  }
  .pg-partnership section.partner_form-sec form.form_sec .pt-lf {
    float: inherit;
    display: block;
    margin: auto;
  }
  .pg-partnership section.partner_form-sec form.form_sec input {
    margin: 10px auto;
    display: block;
    float: inherit;
  }
}
