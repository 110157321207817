.pg-portfolio section.ev-Banner_sec {
  height: 50vh !important;
}
/* Tabs */
.pg-portfolio .port-tabs {
  padding: 50px 0px;
}
.pg-portfolio .portfolio-tabs {
  width: 100%;
  margin: auto;
  background-color: #41973f;
  /* padding: 0% 25%; */
  margin-top: 20px;
}
.pg-portfolio .portfolio-tabs-content {
  padding-top: 50px;
  padding-left: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.pg-portfolio .portfolio-tabs-content div {
  /* width: 300px;
  height: 200px;
  margin: 0px 10px; */
}
.pg-portfolio .portfolio-tabs-content figure {
  /* width: 300px;
  height: 200px; */
  margin: 10px 0px;
}
.pg-portfolio .portfolio-tabs-content figure img {
  width: 100%;
  height: 100%;
  /* margin: 0px 10px; */
}
.pg-portfolio .portfolio-tabs li .active {
  /* background: #41973f !important;
  box-shadow: inset 0px -3px 0px #a3eea1; */
  background: #2c8f2a !important;
  /* box-shadow: inset 0px -3px 0px #a3eea1; */
  border: 3px solid #2c8f2a;
}
.pg-portfolio .portfolio-tabs li {
}
.pg-portfolio .portfolio-tabs li button {
  font-weight: 400;
  font-size: 14px;
  float: left;
  /* width: 20%; */
  padding: 0;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  background: #41ac3e !important;
  height: 7vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  border-radius: 0 !important;
  /* width: 10% !important; */
}

/* Zoom effect on img */
/* .portfolio-tabs-content img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.portfolio-tabs-content div:hover img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  transform-origin: center center;
} */

/* .portfolio-tabs-content div {
  position: relative;
} */
.portfolio-tabs-content div figure {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #fff;
  overflow: hidden;
  position: relative;
}
.portfolio-tabs-content div figure::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
.portfolio-tabs-content div figure:hover::before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}
/* @-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}*/

.port-card {
  background-color: transparent;
  /* width: 300px;
  height: 300px; */
  perspective: 1000px;
  flex: 30% 0;
  margin: 10px;
}
.port-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.port-card:hover .port-card-inner {
  transform: rotateY(180deg);
}
.port-card-front {
  background-color: #fff;
}
.port-card-back {
  background-color: #fff;
  color: #000;
  transform: rotateY(180deg);
  top: 0;
  /* filter: blur(2px); */
}
.port-card-front,
.port-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.port-card-back div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: "Poppins";
}

/* mobile view */
@media screen and (max-width: 480px) {
  .pg-portfolio .portfolio-tabs-content {
    padding-top: 30px;
    padding-left: 0px;
  }
  .port-card-back div p {
    font-size: 12px;
  }
}
