.sidebar {
  width: 260px;
  flex-shrink: 0;
  background-color: rgba(22, 22, 22, 1);
  height: 100%;
  overflow: auto;
}

.sidebar-item {
  /* padding: 0.75em 1em; */
  display: block;
  transition: background-color 0.15s;
  border-radius: 5px;
}
/* .sidebar-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
} */

.sidebar-title {
  display: flex;
  font-size: 1.2em;
  justify-content: space-between;
}
.sidebar-title span i {
  display: inline-block;
  width: 1.5em;
}
.sidebar-title .toggle-btn {
  cursor: pointer;
  transition: transform 0.3s;
}
.sidebar-item.open > .sidebar-title .toggle-btn {
  transform: rotate(180deg);
}
.sidebar-content {
  padding-top: 10px;
  height: 0;
  overflow: hidden;
  /* background-color: rgba(255, 255, 255, 0.1); */
  padding-left: 20px;
}
.sidebar-item.open > .sidebar-content {
  height: auto;
  transition: transform 0.3s;
}
.sidebar-content li {
  font-size: 13px;
  margin-bottom: 5px;
}
