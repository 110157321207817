.pg-acceleration section.ev-Banner_sec {
  padding: 3% 8% 0vh 8%;
}
.pg-acceleration section.ev-Banner_sec {
  height: 60vh;
}
.pg-acceleration section.our_partners .item {
  display: grid;
}
.pg-acceleration section.our_partners .item h3 {
  color: #2d3745;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  margin-top: 4%;
}
.pg-acceleration section.our_partners .slide_box {
  margin-top: 4%;
}

.pg-acceleration section.ev-about-sec .stats_sec ul li {
  width: 25%;
}
body.ev-home.Acceleration_page
  .pg-acceleration
  section.ev-about-sec
  .stats_sec
  ul
  li {
  width: 25%;
}
.pg-acceleration section.ev-about-sec {
  background-size: cover;
}

.pg-acceleration section.ev-about-sec .stats_sec ul li .counter-box p {
  text-align: center;
}
.pg-acceleration section.ev-about-sec.green_shape {
  background: url(/public/images/Marathon/features_bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  background-position: top;
  justify-content: center;
}

.pg-acceleration
  section.ev-about-sec.green_shape
  .about-right_sec
  .about_img::after {
  content: none;
}

.pg-acceleration
  section.ev-about-sec.green_shape
  .about-right_sec
  .about_img::before {
  content: none;
}

.pg-acceleration section.ev-about-sec.green_shape .items .view_box h3 {
  margin-top: 3%;
  font-size: 25px;
  font-family: "Poppins";
  font-weight: 400;
}

.pg-acceleration section.ev-about-sec.green_shape .items .view_box img {
  width: 38px;
  height: 38px;
  object-fit: contain;
}

.pg-acceleration section.ev-about-sec.green_shape .items .view_box {
  display: flex;
  flex-flow: column;
  margin: 0 4%;
}

.pg-acceleration section.ev-about-sec.green_shape .items .view_box p {
  color: #2d3745;
  font-size: 13px;
  margin-top: 2%;
}

.pg-acceleration
  section.ev-about-sec.green_shape
  > .flexi
  > .container
  > .flexi {
  padding-bottom: 4%;
}
.pg-acceleration section.ev-about-sec.green_shape .items {
  padding: 12% 0 4% 0;
}
.pg-acceleration section.ev-about-sec .stats_sec {
  margin-top: 5vh;
}
.pg-acceleration section.ev-Banner_sec {
  padding: 3% 8% 0vh 8%;
}
.pg-acceleration section.ev-Banner_sec > .flexi > .coverup {
  justify-content: center;
}

.pg-acceleration section.ev-about-sec h2 {
  font-size: 30px;
  font-family: "Poppins";
  font-weight: 300;
  color: #000;
}
.pg-acceleration section.methodology_sec.green_shape .about_img {
  padding-right: 12%;
}

.pg-acceleration section.methodology_sec.green_shape .about_img img {
  max-width: 100%;
}
.pg-acceleration section.ev-about-sec.green_shape h3 span {
  color: #41ac3e;
}
.pg-acceleration section.ev-about-sec.green_shape h3 {
  font-size: 30px;
  font-family: "Poppins";
  font-weight: 400;
}
.pg-acceleration section.ev-testimonials_sec {
  background: #fff;
  margin-bottom: 1vh;
}
.pg-acceleration .stats_sec ul li .counter-box a {
  color: #10111a;
  font-size: 52px;
  font-weight: 500;
  line-height: 52px;
  padding: 3%;
  border: 2px dashed #41ac3e;
  border-radius: 104px;
  width: 80px;
  height: 80px;
}

.pg-acceleration section.ev-about-sec .stats_sec ul {
  display: flex;
  width: 100%;
  align-items: flex-start;
}
.pg-acceleration section.ev-about-sec .stats_sec {
  display: flex;
  width: 100%;
  margin-top: 12vh;
}
.pg-acceleration .stats_sec ul li .counter-box {
  display: flex;
  margin: 0 6%;
  flex-flow: column;
  align-items: center;
}
/* .stats_sec ul li .counter-box a {
  color: #10111a;
  font-size: 52px;
  font-weight: 500;
  line-height: 52px;
  padding: 3%;
  border: none;
  border-radius: 104px;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
} */

.pg-acceleration .stats_sec ul li .counter-box a span {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pg-acceleration .stats_sec ul li .counter-box p {
  font-size: 13px;
  color: #717177;
  margin-top: 4%;
}

.pg-acceleration section.pay_info-sec .part {
  display: flex;
  justify-content: space-between;
  border: 1px solid #dfdfdf;
  border-radius: 2vw;
  padding: 8%;
  width: 70%;
  margin: 0 auto;
}
.pg-acceleration section.pay_info-sec .part ul {
  width: 80%;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}
.pg-acceleration section.pay_info-sec .part a {
  width: 20%;
  display: flex;
}
.pg-acceleration section.pay_info-sec .part a img {
  max-width: 100%;
}

.pg-acceleration section.pay_info-sec .part ul li p {
  color: #161c22;
  font-size: 18px;
  font-weight: 300;
  font-family: "Poppins";
}
.pg-acceleration section.pay_info-sec .part ul li h3 {
  font-size: 45px;
  font-family: "Poppins";
  font-weight: 400;
  text-align: center;
  letter-spacing: 0px;
}

.pg-acceleration section.pay_info-sec h2 {
  font-size: 42px;
  font-family: "Poppins";
  font-weight: 400;
  width: 100%;
  padding-bottom: 3%;
  line-height: 42px;
}
.pg-acceleration section.pay_info-sec {
  margin-bottom: 15vh;
}
/* Fueling the startup */
.pg-acceleration section.ev-offering_sec {
  margin-bottom: 8vh;
}

.pg-acceleration section.ev-offering_sec h2.main_head {
  font-size: 42px;
  font-family: "Poppins";
  font-weight: 400;
}
.pg-acceleration section.ev-offering_sec h5 {
  font-size: 24px;
  font-family: "Poppins";
  font-weight: 400;
  margin-top: 2%;
  line-height: 24px;
}
.pg-acceleration section.ev-offering_sec p.para {
  color: #2d3745;
  font-size: 16px;
}

.pg-acceleration section.ev-offering_sec .offering_box {
  overflow: hidden;
  margin: 10% 4%;
  height: 44vh;
  position: relative;
  border-radius: 1vw;
  padding: 10%;
}
.pg-acceleration section.ev-offering_sec .offering_box::after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(20, 29, 60, 0.9),
    rgba(65, 172, 62, 0.9)
  );
  z-index: 0;
}

.pg-acceleration section.ev-offering_sec .first .offering_box {
  background-image: url("/public/images/Home/ATC.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.pg-acceleration section.ev-offering_sec .second .offering_box {
  background: url("/public/images/Home/ATCU.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.pg-acceleration section.ev-offering_sec .third .offering_box {
  background: url("/public/images/Home/ATM.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.pg-acceleration section.ev-offering_sec .fourth .offering_box {
  background: url("/public/images/Home/ATE.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.pg-acceleration section.ev-offering_sec .offering_box a {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-end;
  color: #fff;
  z-index: 999;
  position: relative;
  flex-flow: column;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.pg-acceleration section.ev-offering_sec .offering_box a:hover > p.para {
  display: block !important;
  color: #ffffff;
  font-size: 12px;
  font-weight: 300;
  transition-delay: 1s;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}
.pg-acceleration section.ev-offering_sec .offering_box a:hover > h3::after {
  content: none !important;
}
.pg-acceleration section.ev-offering_sec .offering_box a:hover > h3 {
  margin-bottom: 4%;
}

.pg-acceleration section.ev-offering_sec .offering_box a h3 {
  font-size: 25px;
  font-family: "Poppins";
  font-weight: 300;
  line-height: 30px;
  position: relative;
  margin-bottom: 10%;
  color: #fff;
  transition: all 0.5s ease-out;
}

.pg-acceleration section.ev-offering_sec .offering_box a span {
  width: 3vw;
  height: 3vw;
  display: inline-flex;
  padding: 4%;
  border-radius: 100%;
  border: 1px solid #fff;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
}
.pg-acceleration section.ev-offering_sec .offering_box a img {
  width: 80%;
}
.pg-acceleration section.ev-offering_sec .offering_box a h3::after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 14px;
  height: 12px;
  background: url(/public/images/Home/white_arrow.svg);
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(90deg);
  background-position: bottom;
  bottom: -44%;
  left: 2%;
}

/* Week by week plan */

section.ev-week_plac-sec {
  margin-bottom: 50px;
  font-family: "Poppins";
  padding: 50px 0px;
}
section.ev-week_plac-sec .main_head {
  font-size: 24px;
  font-family: "Poppins";
  margin-bottom: 5px;
}
section.ev-week_plac-sec .accordion-body ul {
  padding: 20px;
}
section.ev-week_plac-sec .accordion-header button {
  background-color: #f8fdf6;
}
section.ev-week_plac-sec .accordion-body h4 {
  font-family: "Poppins";
}
section.ev-week_plac-sec .accordion-body ul li {
  list-style-type: disc;
}
section.ev-week_plac-sec .accordion-header button:focus {
  z-index: 3;
  border-color: red;
  outline: 0;
  box-shadow: blue;
}

/* testimonial */

/* Mobile View */
@media screen and (max-width: 480px) {
  .pg-acceleration section.ev-Banner_sec a {
    width: 55%;
  }
  .pg-acceleration section.ev-about-sec h2 {
    text-align: center;
  }
  .pg-acceleration section.ev-about-sec .stats_sec ul {
    flex-wrap: wrap;
    padding-left: 0px;
  }
  .pg-acceleration section.ev-about-sec .stats_sec ul li {
    width: 50%;
  }
  .pg-acceleration .about-left_sec .inner_wrapper {
    padding: 5%;
  }
  .pg-acceleration .about-left_sec .inner_wrapper ul {
    padding-left: 0px;
  }
  .about-right_sec .about_img {
    position: relative;
    width: 95%;
    margin: auto;
    display: block;
  }
  .pg-acceleration section.pay_info-sec .part {
    display: flex;
    justify-content: space-between;
    border: 1px solid #dfdfdf;
    border-radius: 2vw;
    padding: 8%;
    width: 95%;
    margin: 5% auto;
  }
  .pg-acceleration section.ev-offering_sec .offering_box {
    height: 30vh;
  }
}
