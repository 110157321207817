a {
  text-decoration: none !important;
}

/* Animation Start*/
@-webkit-keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}
@-webkit-keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}
@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/* Animation ENd */

.pg-home section.ev-Banner_sec {
  position: relative;
  padding: 3% 8% 18vh 8% !important;
  /* background: url(../img/home/Banner.svg); */
  background: url(/public/images/Home/Banner.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 85vh !important;
  display: flex;
}
.pg-home section.ev-Banner_sec a {
  width: 40%;
}

.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}
.ocean {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 3px;
}

.pg-home section.ev-about-sec .about-left_sec .inner_wrapper {
  padding: 0% 5% 0 0;
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
}
.pg-home section.ev-about-sec .about-left_sec .inner_wrapper p {
  color: #2d3745;
  font-size: 14px;
}
.pg-home section.ev-about-sec .about-left_sec .inner_wrapper li {
  list-style-type: square;
  margin-left: 20px;
  color: #2d3745;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 12px;
}
.pg-home section.ev-about-sec .about-left_sec .inner_wrapper p:nth-child(2) {
  margin: 4% 0;
}
.pg-home section.ev-about-sec .about-left_sec .inner_wrapper p span {
  color: #41ac3e;
}

.pg-home section.ev-about-sec .about-right_sec .about_img img {
  max-width: 100%;
  border-radius: 1vw;
  box-shadow: 0px 4px 12px #9fb5b2;
}

.pg-home section.ev-about-sec .about-right_sec .about_img {
  position: relative;
}
.pg-home section.ev-about-sec .about-right_sec .about_img::after {
  content: "";
  display: inline-block;
  width: 7vw;
  position: absolute;
  background: #f2f9f8;
  height: 7vw;
  left: -4%;
  top: -7%;
  z-index: -1;
  border-radius: 0.7vw;
}
.pg-home section.ev-about-sec .about-right_sec .about_img::before {
  content: "";
  display: inline-block;
  width: 13vw;
  position: absolute;
  background: #f2f9f8;
  height: 13vw;
  right: -7%;
  bottom: -12%;
  z-index: -1;
  border-radius: 0.7vw;
}
.pg-home section.ev-about-sec .about-right_sec .about_img {
  position: relative;
  width: 90%;
  margin: 0 0 0 auto;
}
.pg-home section.ev-about-sec .about-right_sec .about_img iframe {
  width: 100% !important;
  height: 45vh;
  border-radius: 15px;
}
button.main-cto-second {
  background: linear-gradient(90deg, #04173a, #529a49);
  border: none;
  width: 10vw;
  height: 6vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 0.5vw;
  color: #fff;
  font-size: 12px;
  font-family: "Poppins";
  text-decoration: none;
}
button.main-cto-second img {
  width: 6px;
}

.pg-home section.ev-about-sec .stats_sec ul li {
  width: 20%;
}
.pg-home section.ev-about-sec .stats_sec ul {
  display: flex;
  width: 100%;
  align-items: flex-start;
}
.pg-home section.ev-about-sec .stats_sec {
  display: flex;
  width: 100%;
  margin-top: 12vh;
  flex-direction: column;
}
.pg-home .stats_sec ul li .counter-box {
  display: flex;
  margin: 0 6%;
  flex-flow: column;
  align-items: center;
}
.pg-home .stats_sec ul li .counter-box a {
  color: #10111a;
  font-size: 52px;
  font-weight: 500;
  line-height: 52px;
  padding: 3%;
  border: none;
  border-radius: 104px;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
}

.pg-home .stats_sec ul li .counter-box a span {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pg-home .stats_sec ul li .counter-box p {
  font-size: 13px;
  color: #717177;
  margin-top: 4%;
}
.pg-home section.ev-about-sec {
  margin: 12vh 0;
  background: url(/public/images/Home/dot_bg.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  /* background-color: #fff; */
}
.pg-home section.ev-offering_sec h2.main_head {
  font-size: 42px;
  font-family: "Poppins";
  font-weight: 400;
}
.pg-home section.ev-offering_sec h5 {
  font-size: 24px;
  font-family: "Poppins";
  font-weight: 400;
  margin-top: 2%;
  line-height: 24px;
}
section.ev-offering_sec p.para {
  color: #2d3745;
  font-size: 16px;
}

.pg-home section.ev-offering_sec .offering_box {
  overflow: hidden;
  margin: 10% 4%;
  height: 44vh;
  position: relative;
  border-radius: 1vw;
  padding: 10%;
}
.pg-home section.ev-offering_sec .offering_box::after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(20, 29, 60, 0.9),
    rgba(65, 172, 62, 0.9)
  );
  z-index: 0;
}

.pg-home section.ev-offering_sec .first .offering_box {
  background-image: url("/public/images/Home/ATC.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.pg-home section.ev-offering_sec .second .offering_box {
  background: url("/public/images/Home/ATCU.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.pg-home section.ev-offering_sec .third .offering_box {
  background: url("/public/images/Home/ATM.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.pg-home section.ev-offering_sec .fourth .offering_box {
  background: url("/public/images/Home/ATE.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.pg-home section.ev-offering_sec .offering_box a {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-end;
  color: #fff;
  z-index: 999;
  position: relative;
  flex-flow: column;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.pg-home section.ev-offering_sec .offering_box a:hover > p.para {
  display: block !important;
  color: #ffffff;
  font-size: 12px;
  font-weight: 300;
  transition-delay: 1s;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}
.pg-home section.ev-offering_sec .offering_box a:hover > h3::after {
  content: none !important;
}
.pg-home section.ev-offering_sec .offering_box a:hover > h3 {
  margin-bottom: 4%;
}

.pg-home section.ev-offering_sec .offering_box a h3 {
  font-size: 25px;
  font-family: "Poppins";
  font-weight: 300;
  line-height: 30px;
  position: relative;
  margin-bottom: 10%;
  color: #fff;
  transition: all 0.5s ease-out;
}

.pg-home section.ev-offering_sec .offering_box a span {
  width: 3vw;
  height: 3vw;
  display: inline-flex;
  padding: 4%;
  border-radius: 100%;
  border: 1px solid #fff;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
}
.pg-home section.ev-offering_sec .offering_box a img {
  width: 80%;
}
.pg-home section.ev-offering_sec .offering_box a h3::after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 14px;
  height: 12px;
  background: url(/public/images/Home/white_arrow.svg);
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(90deg);
  background-position: bottom;
  bottom: -44%;
  left: 2%;
}
.pg-home section.ev-news_sec h2.main-head {
  font-size: 42px;
  font-family: "Poppins";
  font-weight: 400;
  margin-bottom: 3%;
}
/* #new_slide .owl-stage-outer {
  width: 90%;
  margin: 0 auto;
}
section.ev-news_sec .no-js .owl-carousel,
.owl-carousel.owl-loaded {
  display: grid;
} */

/* section.ev-news_sec .owl-1 {
  margin-top: 3%;
}
section.ev-news_sec .owl-1 .item .inner_wrapper img {
  width: 100%;
  height: 22vh;
  background: #def1ee;
  border: none;
  border-radius: 1vw;
  object-fit: cover;
  object-position: center;
} */
.pg-home section.ev-news_sec a.news_head span {
  background: #41ac3e;
  border-radius: 0.5vw;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 13px;
  color: #fff;
  font-size: 11px;
  margin-right: 4%;
  padding: 3% 5%;
  width: 6%;
}
.pg-home section.ev-news_sec a.news_head {
  display: flex;
  align-items: center;
  color: #172936;
  font-size: 16px;
  margin-top: 5%;
  line-height: 20px;
  font-weight: 500;
}
.pg-home section.ev-news_sec .inner_wrapper p {
  color: #aeb4b8;
  font-size: 13px;
  font-weight: 300;
  margin-top: 2%;
}
section.ev-testimonials_sec {
  margin: 16vh 0vh;
  background: #f3f9f2;
  position: relative;
  padding: 60px 0px;
}
section.ev-testimonials_sec h2.main_head {
  font-size: 42px;
  font-family: "Poppins";
  font-weight: 400;
  text-align: center;
  width: 100%;
  margin-bottom: 16vh;
}
section.ev-testimonials_sec #testi {
  margin: 4% 0 16vh 0;
  position: relative;
}
/* #testi > .owl-nav,
#new_slide > .owl-nav {
  display: flex !important;
  position: absolute;
  top: 0px;
  bottom: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
#testi > .owl-nav > button,
#new_slide > .owl-nav > button {
  width: 40px;
  height: 40px;
  border: 1px solid #969696;
  border-radius: 30px;
  font-size: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 24px;
} */
/* #testi > .owl-nav > button > span,
#new_slide > .owl-nav > button > span {
  color: #000000;
  font-weight: 300;
} */
section.ev-testimonials_sec .item2 {
  padding: 0px 20px;
}
section.ev-testimonials_sec .item2 img {
  border-radius: 100%;
  max-width: 40%;
  margin: 0 auto 5% auto;
}
section.ev-testimonials_sec .item2 h5 {
  color: #161c22;
  font-size: 15px;
  font-weight: 500;
  display: grid;
  font-family: "Poppins";
  text-align: center;
  line-height: 15px;
}
section.ev-testimonials_sec .item2 h5 span {
  font-size: 12px;
  font-weight: 300;
  margin-top: 1%;
}
section.ev-testimonials_sec .item2 p {
  color: #161c22;
  font-size: 13px;
  font-weight: 300;
  font-family: "Poppins";
  margin-top: 6%;
  text-align: center;
}
section.ev-testimonials_sec::after {
  content: "";
  display: inline-block;
  width: 7vw;
  height: 6vw;
  position: absolute;
  top: -4%;
  background: url(/public/images/Home/quote.svg);
  background-repeat: no-repeat;
  background-size: contain;
  left: 5%;
}
.pg-home .ev-subscribe_sec {
  padding: 60px 0px;
}
.pg-home section.ev-subscribe_sec h2.main_head {
  font-size: 42px;
  font-family: "Poppins";
  font-weight: 400;
  text-align: center;
  width: 100%;
}
.pg-home section.ev-subscribe_sec p {
  font-size: 25px;
  font-family: "Poppins";
  font-weight: 300;
  text-align: center;
  width: 100%;
}
.pg-home section.ev-subscribe_sec form.subscribe {
  display: flex;
  width: 100%;
  margin-top: 3%;
  justify-content: center;
}
.pg-home section.ev-subscribe_sec form.subscribe input:focus {
  outline: none;
}
.pg-home section.ev-subscribe_sec form.subscribe input {
  width: 26%;
  border: 1px solid #c8dbcb;
  padding: 0.6% 2% 0.6% 1%;
  border-right: 0;
  border-radius: 0.5vw;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
  color: #181818;
  margin-right: -6px;
}
.pg-home section.ev-subscribe_sec form.subscribe button {
  background: #41ac3e;
  border: none;
  padding: 1% 2%;
  color: #fff;
  border-top-right-radius: 0.5vw;
  border-bottom-right-radius: 0.5vw;
  display: flex;
  align-items: center;
  width: 10vw;
  justify-content: space-between;
  height: fit-content;
  margin: 0;
}
.pg-home section.ev-subscribe_sec form.subscribe button img {
  width: 6px;
  margin-left: 15%;
  display: inline-block;
}

/* Submit Button */
.submit-btn:hover {
}
.pg-home .ev-news_sec {
  padding: 50px 0px;
}
.pg-home .ev-news_sec .item img {
  display: block;
  width: 100%;
  height: 180px;
  object-fit: cover;
  margin: 0 auto;
  object-position: center;
}
.pg-home .ev-news_sec .item {
  padding: 0px 10px;
}
.slick-prev:before,
.slick-next:before {
  font-family: initial !important;
  color: #000;
}
.slick-list {
  margin: 0px 20px;
}

/* Slick common settings */
.slick-prev:before {
  content: "\2794";
  transform: rotate(180deg);

  display: inline-block;
}
.slick-next:after {
  content: "\2794";

  display: inline-block;
}
.slick-next:before,
[dir="rtl"] .slick-prev:before {
  content: "\2794";

  display: inline-block;
}
/* Slick common settings end */

/* Mobile View */
@media screen and (max-width: 480px) {
  .header .logo img {
    max-width: 20vw;
  }
  .pg-home section.ev-Banner_sec {
    background-position: inherit;
    height: 70vh !important;
  }
  section.ev-Banner_sec h1 {
    font-size: 30px;
  }
  section.ev-Banner_sec p {
    font-size: 16px;
    line-height: 28px;
    margin: 4% 0;
    letter-spacing: 0.5px;
  }
  .pg-home section.ev-Banner_sec a {
    width: 60%;
  }
  section.ev-Banner_sec a > button {
    font-size: 18px;
  }
  .pg-home section.ev-about-sec .about-left_sec .inner_wrapper {
    align-items: center;
    text-align: center;
  }
  .waves {
    height: 11vh;
  }
  .pg-home section.ev-about-sec {
    margin: 7vh 0;
  }
  .pg-home section.ev-about-sec .about-left_sec .inner_wrapper p {
    font-size: 16px;
  }

  .pg-home button.main-cto-second {
    background: linear-gradient(90deg, #04173a, #529a49);
    border: none;
    width: 24vw;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 0.5vw;
    color: #fff;
    font-size: 12px;
    font-family: "Poppins";
    text-decoration: none;
    margin-bottom: 20px;
    border-radius: 0.5vw;
  }
  .pg-home section.ev-about-sec .about-right_sec .about_img iframe {
    width: 90% !important;
    margin: 0px 20px;
  }
  .pg-home section.ev-about-sec .about-right_sec .about_img {
    width: 100%;
  }
  .pg-home section.ev-about-sec .stats_sec ul {
    flex-wrap: wrap;
    padding-left: 0px;
  }
  .pg-home section.ev-about-sec .stats_sec ul li {
    width: 50%;
  }
  .pg-home section.ev-offering_sec .offering_box {
    height: 30vh;
  }
  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0px;
  }
  .pg-home section.ev-about-sec .about-right_sec .about_img::before {
    right: 0%;
  }
  section.ev-testimonials_sec {
    margin: 5vh 0vh;
  }
  section.ev-testimonials_sec::after {
    width: 16vw;
    height: 13vw;
    top: -2%;
  }
  section.ev-testimonials_sec h2.main_head {
    margin-bottom: 8vh;
  }
  .pg-home section.ev-subscribe_sec form.subscribe input {
    width: 50%;
  }
  .pg-home section.ev-subscribe_sec form.subscribe button {
    width: 25vw;
  }
}
