.pg-career section.ev-Banner_sec {
  background: #f7f7f7;
  height: 100%;
  padding: 3% 8% 0vh 8%;
}

.pg-career section.ev-Banner_sec h1 {
  color: #172936;
  width: 50%;
  font-weight: 400;
}
.pg-career section.ev-Banner_sec p {
  color: #838697;
  margin: 4% 0;
  font-weight: 300;
}

.pg-career .banner_image {
  display: flex;
  justify-content: flex-end;
}
.pg-career .banner_image img {
  max-width: 82%;
}

.pg-career button.main-cto-first {
  background: linear-gradient(90deg, #04173a, #529a49);
  color: #fff;
  border: none;
  padding: 10px;
  width: 180px;
  border-radius: 4px;
  cursor: pointer;
}
.pg-career section.carrer_sec {
  background: #f7f7f7;
  padding: 3% 0%;
}
.pg-career section.carrer_sec .card_sec .card {
  background: #fff;
  margin: 5% 0%;
  padding: 10%;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 5%);
  border-radius: 10px;
  border: none;
}
.pg-career section.carrer_sec .card_sec .card img {
  width: 42px;
  height: 42px;
  object-fit: contain;
}

.pg-career section.carrer_sec .card_sec .card h4 {
  color: #172936;
  font-weight: 400;
  font-size: 20px;
  font-family: "Poppins";
  line-height: 26px;
  margin: 5% 0;
}
.pg-career section.carrer_sec .card_sec .card p {
  color: #2d3745;
  font-size: 13px;
  line-height: 17px;
}
.pg-career section.carrer_sec .career_info {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  padding-left: 15%;
}
.pg-career section.carrer_sec .career_info h2 {
  color: #172936;
  font-weight: 500;
  font-size: 28px;
  font-family: "Poppins";
  line-height: 26px;
}
.pg-career section.carrer_sec .career_info p {
  color: #2d3745;
  font-size: 14px;
  line-height: 22px;
}

.pg-career section.open_job-sec .job_view {
  background: #fff;
  width: 100%;
  padding: 5%;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 5%);
  border-radius: 10px;
}
.pg-career section.open_job-sec {
  background: #f7f7f7;
  padding: 4% 0;
}
.pg-career section.open_job-sec .job_view h3 {
  color: #172936;
  font-weight: 400;
  font-size: 20px;
  font-family: "Poppins";
  line-height: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pg-career section.open_job-sec .job_view h3 span {
  color: #2d3745;
  font-size: 14px;
  line-height: 22px;
}

.pg-career section.open_job-sec .job_view form .search_box input {
  width: 100%;
  display: block;
  padding: 5px 15px;
  width: 100%;
  box-sizing: border-box;
  background-color: #f4f5fc;
  border: none;
  height: 40px;
  border-radius: 5px;
  outline: none;
  color: #5d5e62;
  font-weight: 400;
}

.pg-career section.open_job-sec .job_view form select {
  display: block;
  padding: 5px 28px 5px 15px;
  width: 99%;
  font-weight: 400;
  color: #5d5e62;
  box-sizing: border-box;
  background-color: #f4f5fc;
  border: none;
  height: 40px;
  border-radius: 5px;
  outline: none;
  background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-size: 8px;
  background-position: 93%;
  background-repeat: no-repeat;
  margin: 0 auto;
}

.pg-career section.open_job-sec .job_view form ul div {
  width: 100%;
  margin-bottom: 1.5%;
}

.pg-career section.open_job-sec .job_view form ul {
  display: flex;
  flex-wrap: wrap;
}

.pg-career section.open_job-sec .job_view form ul li {
  width: 33.33%;
}

.pg-career section.open_job-sec .job_view form {
  margin-top: 3%;
}

.pg-career .job_post-sec {
  display: flex;
  width: 100%;
  margin-top: 4%;
}

.pg-career .job_post-sec ul {
  display: flex;
  width: 100%;

  flex-wrap: wrap;
}
.pg-career .job_post-sec ul li {
  display: flex;
  width: 33.33%;
  margin: 1% 0;
}
.pg-career .job_post-sec ul li .job_card {
  background: #fff;
  width: 100%;
  margin: 0 5%;
  padding: 8%;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 5%);
  border-radius: 10px;
}
.pg-career .job_post-sec ul li .job_card h4 {
  color: #172936;
  font-weight: 400;
  font-size: 20px;
  font-family: "Poppins";
  line-height: 26px;
}
.pg-career .job_post-sec ul li .job_card .job_detail {
  display: flex;
  align-items: center;
  margin: 5% 0 7% -4%;
}

.pg-career .job_post-sec ul li .job_card .job_detail span {
  color: #2d3745;
  font-size: 14px;
  line-height: 22px;
  margin: 0 2%;
}
.pg-career .job_post-sec ul li .job_card .job_detail span.for {
  color: #41ac3e;
  cursor: pointer;
}
.pg-career .job_post-sec ul li .job_card .job_detail span.for:hover {
  text-decoration: underline;
}
.pg-career .job_post-sec ul li .job_card button {
  background: rgb(65 172 62 / 10%);
  width: 110px;
  height: 36px;
  border: none;
  border-radius: 50px;
  font-size: 12px;
  color: #41ac3e;
  cursor: pointer;
}

.pg-career section.highlighhts {
  background: #f7f7f7;
  padding: 5% 0;
}
.pg-career .highlight_title {
  width: 100%;
  background: #41ac3e;
  padding: 5%;
}
.pg-career .highlight_title h2 {
  font-size: 35px;
  font-family: "Poppins";
  font-weight: 300;
  color: #fff;
  line-height: 44px;
  text-align: center;
  width: 70%;
  margin: 0 auto;
}
.pg-career section.requirtment_sec {
  background: #f7f7f7;
  padding: 3% 0 5% 0;
}
.pg-career section.requirtment_sec h2 {
  font-size: 30px;
  font-family: "Poppins";
  font-weight: 400;
  color: #172936;
  line-height: 44px;
  text-align: center;
  width: 70%;
  margin: 0 auto;
}
.pg-career section.requirtment_sec ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3%;
}

.pg-career section.requirtment_sec ul li {
  width: 25%;
}

.pg-career section.requirtment_sec ul li .card {
  background: #fff;
  margin: 0 5%;
  padding: 9%;
  display: flex;
  flex-flow: column;
  height: 210px;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 5%);
  border-radius: 10px;
}
.pg-career section.requirtment_sec .recruit {
  display: flex;
  flex-flow: column;
}
.pg-career section.requirtment_sec ul li .card img {
  width: 32px;
}
.pg-career section.requirtment_sec ul li .card h4 {
  color: #172936;
  font-weight: 400;
  font-size: 20px;
  font-family: "Poppins";
  line-height: 26px;
  margin: 5% 0;
}
.pg-career section.requirtment_sec ul li .card p {
  color: #2d3745;
  font-size: 13px;
  line-height: 17px;
}

/* mobile view */
@media screen and (max-width: 480px) {
  .pg-career .banner_image img {
    display: none;
  }
  .pg-career .job_post-sec ul {
    flex-wrap: wrap;
    padding-left: 0;
  }
  .pg-career .job_post-sec ul li {
    width: 100%;
    margin: 2% 0;
  }
  .pg-career section.requirtment_sec ul {
    padding-left: 0;
  }
  .pg-career section.requirtment_sec ul li {
    width: 100%;
    margin: 2% 0;
  }
}
