.pg-footer footer.ev-footer_sec {
  background: #f2f8f1;
}
.pg-footer footer.ev-footer_sec .main_wrap {
  padding: 9vh 0 8vh 0;
}
.pg-footer footer.ev-footer_sec .first .inner_wrapper img {
  width: 64%;
}
.pg-footer footer.ev-footer_sec .first .inner_wrapper {
  padding: 0 15%;
}
.pg-footer footer.ev-footer_sec .second .inner_wrapper h3,
.pg-footer footer.ev-footer_sec .third .inner_wrapper h3 {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 3%;
}
.pg-footer footer.ev-footer_sec .second .inner_wrapper ul {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-left: 0px;
}
.pg-footer footer.ev-footer_sec .second .inner_wrapper {
  margin-right: 15%;
}
.pg-footer footer.ev-footer_sec .second .inner_wrapper ul li {
  display: flex;
  width: 78%;
  margin: 4% 0;
}
.pg-footer footer.ev-footer_sec .second .inner_wrapper ul div li a {
  color: #828282;
  font-size: 13px;
  font-family: "Poppins";
  font-weight: 400;
  display: block;
}
.pg-footer footer.ev-footer_sec .second .inner_wrapper ul div {
  width: 50%;
}
.pg-footer footer.ev-footer_sec .third .inner_wrapper ul {
  display: flex;
  flex-flow: column;
  padding-left: 0px;
}
.pg-footer footer.ev-footer_sec .third .inner_wrapper ul li {
  width: 100%;
  margin: 1% 0;
}
.pg-footer footer.ev-footer_sec .third .inner_wrapper ul li a {
  color: #828282;
  font-size: 13px;
  font-family: "Poppins";
  font-weight: 400;
  display: flex;
  line-height: 17px;
}
.pg-footer footer.ev-footer_sec .third .inner_wrapper ul li a img {
  width: 14px;
  margin-right: 4%;
}
.pg-footer footer.ev-footer_sec .second .inner_wrapper p a img {
  max-width: 100%;
}
.pg-footer footer.ev-footer_sec .second .inner_wrapper p a {
  display: inline-flex;
  width: 3vw;
  margin: 0 1.5%;
  cursor: pointer;
}
.pg-footer footer.ev-footer_sec .second .inner_wrapper p {
  display: flex;
  margin-top: 4%;
}
.pg-footer a.copy-right {
  width: 100%;
  display: flex;
  font-size: 12px;
  padding: 1%;
  background: linear-gradient(90deg, #141d3c, #41ac3e);
  color: #fff;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-weight: 200;
  text-decoration: none;
}
.pg-footer a.copy-right:hover {
  color: #fff;
}

.pg-footer button.green_btn {
  background: #41ac3e;
  width: 100%;
  margin: 4% auto 0 auto;
  padding: 1% 2%;
  height: 7vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: none;
  border-radius: 0.5vw;
  cursor: pointer;
}
/* body.ev-home.marathon_page section.ev-apply button.green_btn {
  width: 40%;
} */

.pg-footer
  footer.ev-footer_sec
  .footer
  .pg-footer
  footer.ev-footer_sec
  .footer_content {
  padding: 4% 0;
  border-top: 1px solid #c6c6c6;
}

.pg-footer footer.ev-footer_sec .footer_content p {
  color: #828282;
  font-size: 13px;
  font-weight: 400;
  font-family: "Poppins";
  line-height: 17px;
}

.pg-footer footer.ev-footer_sec .footer_content p b {
  font-weight: 500;
  color: #3f433e;
}
.pg-footer footer.ev-footer_sec .footer_content .info {
  margin: 2% 0;
}
.pg-footer footer.ev-footer_sec .footer_content .info h4 {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 1px;
}
_content .info ol {
  padding: 10px 0 10px 30px;
}
.pg-footer footer.ev-footer_sec .footer_content .info ol li {
  color: #828282;
  font-size: 13px;
  font-weight: 400;
  font-family: "Poppins";
  line-height: 17px;
  margin: 4px 0;
  list-style: disc;
}
.pg-footer footer.ev-footer_sec .footer_content .info ol li b {
  font-weight: 600;
  margin-right: 5px;
  width: 100px;
  display: block;
  float: left;
  color: #3f433e;
}

/* Mobile View */
@media screen and (max-width: 480px) {
  .pg-footer footer.ev-footer_sec .main_wrap {
    padding: 4vh 0 8vh 0;
  }
  .pg-footer footer.ev-footer_sec .first .inner_wrapper img {
    width: 60%;
    margin: auto;
    display: block;
  }
  .pg-footer footer.ev-footer_sec .second .inner_wrapper {
    margin-right: 15%;
    margin-top: 10%;
  }
  .pg-footer footer.ev-footer_sec .second .inner_wrapper p a {
    width: 10vw;
  }
  .pg-footer .second {
    margin-bottom: 30px;
  }
}
