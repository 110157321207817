.header {
  background-color: #fff;
  width: 100%;
  z-index: 999;
  display: flex;
  padding: 0.5% 0;
  box-shadow: 1px 5px 6px #0000001a;
  position: relative;
}
header.header .inner_wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 8%;
  align-items: center;
}

.header button.head-cto {
  background: #41ac3e;
  border: none;
  color: #fff;
  width: 10vw;
  height: 5vh;
  border-radius: 0.5vw;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 400;
}
.header .head-cto {
  background: #41ac3e;
  border: none;
  color: #fff;
  width: 10vw;
  height: 5vh;
  border-radius: 0.5vw;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 400;
}

.header button.head-cto img {
  width: 6px;
}
.header .logo img {
  max-width: 5.5vw;
}
.header li:last-child a {
  border-right: 0px;
}

.header .logo {
  display: flex;
  float: left;
  align-items: center;
  justify-content: left;
  width: 10%;
}
.header input#menu-btn {
  display: none;
}

.toggle,
[id^="drop"] {
  display: none;
}

/* Since we'll have the "ul li" "float:left"
 * we need to add a clear after the container. */

.header:after {
  content: "";
  display: table;
  clear: both;
}

/* Removing padding, margin and "list-style" from the "ul",
 * and adding "position:reltive" */
.header ul {
  float: right;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  align-items: center;
  display: flex;
}

/* Positioning the navigation items inline */
.header ul li {
  margin: 0px;
  display: inline-block;
  float: left;
  height: fit-content;
}

/* Styling the links */
.header a {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  font-size: 13px;
  font-family: "Poppins";
  color: #181818;
  cursor: pointer;
}

.header ul li ul li:hover {
  background: #f1f1f1;
}

/* Background color change on Hover */
.header a:hover {
  border-bottom: 1px solid #41ac3e;
  color: #000;
}
.header .header-account a:hover {
  border-bottom: 1px solid #41ac3e;
  color: #fff;
}
.header .header-account li a:hover {
  border-bottom: 1px solid #41ac3e;
  color: #000;
}
.header .logo:hover {
  border-bottom: none;
}
/* Hide Dropdowns by Default
 * and giving it a position of absolute */
.header ul ul {
  display: none;
  position: absolute;
  /* has to be the same number as the "line-height" of "nav a" */
  top: 35px;
}

/* Display Dropdowns on Hover */
.header ul li:hover > ul {
  display: flex;
  flex-flow: column;
  background: #fff;
  z-index: 999;
  border: 1px solid #ededed;
  border-radius: 4px;
}

/* Fisrt Tier Dropdown */
.header ul ul li {
  width: 170px;
  float: none;
  display: list-item;
  position: relative;
  z-index: 9999;
}
.header .header-account li {
  width: 153px;
}
/* Second, Third and more Tiers 
 * We move the 2nd and 3rd etc tier dropdowns to the left
 * by the amount of the width of the first tier.
*/
.header ul ul ul li {
  position: relative;
  top: -60px;
  /* has to be the same number as the "width" of "nav ul ul li" */
  left: 170px;
}

.header li:last-child a:hover {
  border: none;
}
.mobile-drop {
  display: none;
}
/* Mobile Menu Icon */
.mobile-menu-icon {
  display: none;
}

.dropdown-content {
  display: block;
  animation: fadeIn 2s;
  margin-left: 45px;
}
.nodrop {
  display: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Mobile View */
@media screen and (max-width: 480px) {
  /* Offcanvas body || Mobile Menu*/
  .btn-close {
    background: transparent url(/public/images/cross1.svg) center/1em auto
      no-repeat !important;
    opacity: 1;
  }
  .offcanvas {
    background: url(/public/images/Home/Banner.svg);
    padding: 15px;
  }
  .offcanvas-title {
    color: #fff;
  }
  .offcanvas-body .menu li {
    font-size: 24px;
    color: #fff;
    margin: 20px 0px;
  }
  .offcanvas-body .menu li svg {
    margin-right: 20px;
  }
  .offcanvas-body .menu li a {
    color: #fff;
  }
  .header .logo img {
    max-width: 20vw;
  }
  /* Mobile Menu */
  .mobile-drop {
    display: inline-block;
    margin-left: 10px;
  }
  .mobile-mdrop {
    display: inline-block;
    margin-left: 10px;
  }
  .mobile-menu-icon {
    display: inline-block;
  }
  .login-arrow {
    display: none;
  }
}
