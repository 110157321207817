.swiper-pagination {
  left: 20px !important;
}
.swiper-pagination-bullet {
  width: fit-content;
  border-left: 1px solid #fff;
  border-radius: 0px;
  background-color: transparent !important;
  color: #fff;
  margin: 15px 0px !important;
  padding: 20px 0px 40px 10px;
  font-size: 16px;
  font-weight: 400;
}
.swiper-slide-content {
  margin-left: 20%;
  display: flex;
  /* justify-content: center;  */
  /* align-items: center; */
  color: #fff;
  flex-direction: column;
  padding: 6% 0%;
}
.slide-main {
  /* filter: brightness(50%); */
}
.timeline .swiper-slide-active .timeline-year {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: 0.4s ease 1.6s;
}
.swiper-slide .timeline-year {
  display: flex;
  font-size: 42px;
  transform: translate3d(20px, 0, 0);
  color: #d4a024;
  font-weight: 300;
  /* opacity: 0; */
  transition: 0.2s ease 0.4s;
  align-items: flex-end;
  margin-bottom: 4%;
}
.stroke-text {
  -webkit-text-stroke: 2px #fff;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}
.swiper-slide .timeline-year h4 {
  margin-bottom: 0;
  font-size: 170px;
  font-family: "Poppins" !important;
  font-style: normal;
  display: flex;
  line-height: 140px;
  align-items: flex-start;
}
.swiper-slide .timeline-year span.year {
  margin: 0 0 3% -13%;
  font-size: 62px;
  line-height: 62px;
  font-weight: 600;
  color: #fff;
  display: flex;
}

.swiper-slide .timeline-title {
  font-size: 40px;
  margin: 0;
  color: #fca733;
  font-family: "Poppins";
  font-weight: 600;
  text-align: left;
  padding-bottom: 10px;
}
.swiper-slide .timeline-text {
  font-size: 14px;
  font-weight: 200;
  line-height: 20px;
  margin-top: 2%;
  width: 60%;
}

/* Swiper Nav btn */
.swiper-button-prev {
  top: 90px;
  left: 40px;
  transform: rotate(90deg);
  color: #fff;
  z-index: 999;
}
.swiper-button-next {
  left: 40px;
  bottom: 70px;
  top: auto;
  transform: rotate(90deg);
  color: #fff;
  z-index: 999;
}
