.blog-content-info {
  padding: 50px 0px;
}
.blog-content-info img {
  width: 60%;
  padding: 10px 0px;
}
.blog-content section.ev-Banner_sec {
  height: 25vh;
  background-size: cover;
  background-position: inherit;
  padding: 0px 8%;
}
