.pg-about section.ev-work-withUs_sec {
  margin-bottom: 16vh;
}
.pg-about section.ev-work-withUs_sec > .flexi > .container > .flexi {
  flex-flow: column;
  text-align: center;
  background: url(/public/images/About/arrow_bg.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
}
.pg-about section.ev-work-withUs_sec > .flexi > .container > .flexi::after {
  content: "";
  display: block;
  height: 100%;
  width: 20vw;
  position: absolute;
  left: 0;
  background: url(/public/images/About/WWU-left.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  bottom: -16vh;
}
.pg-about section.ev-work-withUs_sec > .flexi > .container > .flexi::before {
  content: "";
  display: block;
  height: 100%;
  width: 20vw;
  position: absolute;
  right: 0;
  background: url(/public/images/About/WWU-right.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  bottom: -16vh;
}

.pg-about section.ev-work-withUs_sec .main_head {
  font-size: 30px;
  font-family: "Poppins";
  font-weight: 600;
}
.pg-about section.ev-work-withUs_sec p {
  color: #2d3745;
  font-size: 12px;
  margin-top: 4px;
}
.pg-about section.ev-work-withUs_sec p.para {
  color: #2d3745;
  font-size: 14px;
  width: 60%;
  margin: 16px auto 0 auto;
}
.pg-about
  section.ev-work-withUs_sec
  > .flexi
  > .container
  > .flexi
  > button.green_btn
  img {
  width: 15px;
  height: 12px;
}

.pg-about
  section.ev-work-withUs_sec
  > .flexi
  > .container
  > .flexi
  > a
  button.green_btn {
  width: 158px;
  justify-content: space-between;
}

.pg-about section.ev-about-sec .about-left_sec .inner_wrapper {
  padding: 0% 10% 0 0;
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
}
.pg-about section.ev-about-sec .about-left_sec .inner_wrapper p {
  color: #2d3745;
  font-size: 14px;
}
.pg-about section.ev-about-sec .about-left_sec .inner_wrapper li {
  list-style-type: square;
  margin-left: 20px;
  color: #2d3745;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 12px;
}
.pg-about section.ev-about-sec .about-left_sec .inner_wrapper p:nth-child(2) {
  margin: 4% 0;
}
.pg-about section.ev-about-sec .about-left_sec .inner_wrapper p span {
  color: #41ac3e;
}

.pg-about section.ev-about-sec .about-right_sec .about_img img {
  max-width: 100%;
  border-radius: 1vw;
  box-shadow: 0px 4px 12px #9fb5b2;
}

.pg-about section.ev-about-sec .about-right_sec .about_img {
  position: relative;
}
.pg-about section.ev-about-sec .about-right_sec .about_img::after {
  content: "";
  display: inline-block;
  width: 7vw;
  position: absolute;
  background: #f2f9f8;
  height: 7vw;
  left: -4%;
  top: -7%;
  z-index: -1;
  border-radius: 0.7vw;
}
.pg-about section.ev-about-sec .about-right_sec .about_img::before {
  content: "";
  display: inline-block;
  width: 13vw;
  position: absolute;
  background: #f2f9f8;
  height: 13vw;
  right: -7%;
  bottom: -12%;
  z-index: -1;
  border-radius: 0.7vw;
}
.pg-about section.ev-about-sec .about-right_sec .about_img {
  position: relative;
  width: 80%;
  margin: 0 0 0 auto;
}
.pg-about section.ev-about-sec .about-right_sec .about_img iframe {
  width: 100% !important;
  height: 45vh;
  border-radius: 15px;
}
.pg-about section.ev-about-sec {
  margin: 12vh 0;
  background: url(/public/images/Home/dot_bg.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* Philosphy */
.pg-about section.ev-philosophy_sec {
  margin-bottom: 15vh;
}
.pg-about section.ev-philosophy_sec h3 {
  font-size: 42px;
  font-family: "Poppins";
  font-weight: 400;
  color: #000;
}
.pg-about section.ev-philosophy_sec p {
  color: #2d3745;
  font-size: 14px;
  margin-top: 8px;
}
.pg-about section.ev-philosophy_sec ul {
  display: flex;
  margin: 2% 0 0 0;
}
.pg-about section.ev-philosophy_sec ul li {
  width: 20%;
  padding: 1.2%;
}
.pg-about section.ev-philosophy_sec ul li a {
  display: grid;
}
.pg-about section.ev-philosophy_sec ul li a h5 {
  font-size: 22px;
  font-family: "Poppins";
  font-weight: 400;
}
.pg-about section.ev-philosophy_sec ul li a p {
  color: #2d3745;
  font-size: 12px;
  margin-top: 4px;
}
.pg-about section.ev-philosophy_sec ul li a img {
  width: 54px;
  height: 54px;
  margin-bottom: 6px;
}
.pg-about section.ev-philosophy_sec {
  margin-bottom: 15vh;
}
.pg-about section.ev-philosophy_sec h3 {
  font-size: 42px;
  font-family: "Poppins";
  font-weight: 400;
}
.pg-about section.ev-philosophy_sec p {
  color: #2d3745;
  font-size: 14px;
  margin-top: 8px;
}
.pg-about section.ev-philosophy_sec ul {
  display: flex;
  margin: 2% 0 0 0;
}
.pg-about section.ev-philosophy_sec ul li {
  width: 20%;
  padding: 1.2%;
}
.pg-about section.ev-philosophy_sec ul li a {
  display: grid;
}
.pg-about section.ev-philosophy_sec ul li a h5 {
  font-size: 22px;
  font-family: "Poppins";
  font-weight: 400;
}
.pg-about section.ev-philosophy_sec ul li a p {
  color: #2d3745;
  font-size: 12px;
  margin-top: 4px;
}
.pg-about section.ev-philosophy_sec ul li a img {
  width: 54px;
  height: 54px;
  margin-bottom: 6px;
}

.pg-about button.green_btn {
  background: #41ac3e;
  width: 100%;
  margin: 4% auto 0 auto;
  padding: 1% 2%;
  height: 7vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: none;
  border-radius: 0.5vw;
  cursor: pointer;
}

/* Mobile View */
@media screen and (max-width: 480px) {
  .pg-about section.ev-about-sec .about-left_sec .inner_wrapper {
    padding: 0% 5%;
  }
  .pg-about section.ev-about-sec .about-right_sec .about_img {
    width: 95%;
    margin: auto;
  }
  .pg-about section.ev-about-sec .about-right_sec .about_img::before {
    right: -3%;
    bottom: -4%;
  }
  .pg-about section.ev-philosophy_sec ul {
    flex-wrap: wrap;
  }
  .pg-about section.ev-philosophy_sec ul li {
    width: 50%;
    padding: 1.2%;
  }
  .pg-about section.ev-work-withUs_sec > .flexi > .container > .flexi::before {
    display: none;
  }
  .pg-about section.ev-work-withUs_sec > .flexi > .container > .flexi::after {
    display: none;
  }
  .pg-about section.ev-work-withUs_sec p {
    font-size: 14px;
  }
  .pg-about section.ev-work-withUs_sec p.para {
    font-size: 16px;
    width: 100%;
  }
  .pg-about
    section.ev-work-withUs_sec
    > .flexi
    > .container
    > .flexi
    > button.green_btn {
    width: 100px;
    justify-content: space-between;
    height: 5vh;
  }
}
