.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ~~~~~ Common CSS ~~~~~ */

::-webkit-scrollbar {
  height: 0;
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background: #239ad8;
  margin-top: 10px;
}

::-webkit-scrollbar-track {
  background: #bdbdbd;
}

::-moz-scrollbar {
  height: 0;
  width: 4px;
}

::-moz-scrollbar-thumb {
  background: #239ad8;
  margin-top: 10px;
}

::-moz-scrollbar-track {
  background: #bdbdbd;
}

button:focus {
  outline: 0;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

body,
html,
ul,
* {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-family: "Cocon";
}

p,
a,
span,
input,
textarea,
label,
ul,
li,
button {
  padding: 0;
  margin: 0;
  font-family: Poppins;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

@font-face {
  font-family: "Cocon";
  /* src: url(../fonts/Cocon-Bold.woff2) format("woff2"), */
  src: url(/public/fonts/Cocon-Bold.woff2) format("woff2")
      url(/public/fonts/Cocon-Bold.woff) format("woff"),
    url(/public/fonts/Cocon-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* Banner Common */

section.ev-Banner_sec {
  position: relative;
  padding: 3% 8% 18vh 8%;
  /* background: url(../img/home/Banner.svg); */
  background: url(/public/images/Home/Banner.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 80vh;
  display: flex;
}
section.ev-Banner_sec h1 {
  font-size: 40px;
  font-family: "Poppins";
  font-weight: 300;
  color: #fff;
  line-height: 44px;
  width: 100%;
  margin-bottom: 1%;
}
section.ev-Banner_sec p {
  font-size: 16px;
  font-family: "Poppins";
  color: #f28c2e;
  font-weight: 500;
  line-height: 24px;
  margin: 2% 0;
}
section.ev-Banner_sec a {
  display: grid;
  font-size: 10px;
  font-family: "Poppins";
  font-weight: 200;
  color: #fff;
  line-height: 18px;
  width: 40%;
  text-align: center;
  text-decoration: none;
}
section.ev-Banner_sec a > button {
  border: 1px solid #41ac3e;
  background: transparent;
  padding: 4%;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  border-radius: 6px;
  font-family: "Poppins";
}

section.ev-Banner_sec > .flexi > .coverup {
  display: flex;
  flex-flow: column;
  justify-content: center;
}
/* about common */

.about-left_sec .inner_wrapper {
  padding: 0% 5% 0 0;
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
}
.about-left_sec .inner_wrapper p {
  color: #2d3745;
  font-size: 14px;
}
.about-left_sec .inner_wrapper li {
  list-style-type: disc;
  margin-left: 20px;
  color: #2d3745;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 12px;
}
.about-left_sec .inner_wrapper p:nth-child(2) {
  margin: 4% 0;
}
.about-left_sec .inner_wrapper p span {
  color: #41ac3e;
}

.about-right_sec .about_img img {
  max-width: 100%;
  border-radius: 1vw;
  box-shadow: 0px 4px 12px #9fb5b2;
}

.about-right_sec .about_img {
  position: relative;
}
.about-right_sec .about_img::after {
  content: "";
  display: inline-block;
  width: 7vw;
  position: absolute;
  background: #f2f9f8;
  height: 7vw;
  left: -4%;
  top: -7%;
  z-index: -1;
  border-radius: 0.7vw;
}
.about-right_sec .about_img::before {
  content: "";
  display: inline-block;
  width: 13vw;
  position: absolute;
  background: #f2f9f8;
  height: 13vw;
  right: -7%;
  bottom: -12%;
  z-index: -1;
  border-radius: 0.7vw;
}
.about-right_sec .about_img {
  position: relative;
  width: 80%;
  margin: 0 0 0 auto;
}
.about-right_sec .about_img iframe {
  width: 100% !important;
  height: 45vh;
  border-radius: 15px;
}
section.ev-about-sec {
  margin: 12vh 0;
  background: url(/public/images/Home/dot_bg.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
