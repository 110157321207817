.pg-media section.ev-Banner_sec {
  height: 50vh !important;
}
.media-container .media-tabs li button {
  font-weight: 400;
  font-size: 14px;
  float: left;
  /* width: 20%; */
  padding: 0;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  background: #41ac3e !important;
  height: 7vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  border-radius: 0 !important;
}

.media-container .media-tabs li .active {
  background: #2c8f2a !important;
  /* box-shadow: inset 0px -3px 0px #a3eea1; */
  border: 3px solid #2c8f2a;
}
.media-container {
  /* border-left: 1px solid #e6e6e6; */
  padding-top: 70px;
  margin-bottom: 15vh;
}
.media-container .media-tabs-content {
  padding-top: 10px;
  padding-left: 20px;
}
.media-container .blog_highlight-img_sec {
  padding-right: 30px;
  height: 45vh;
}
.media-container .blog_highlight-img_sec img {
  width: 100%;
  object-fit: cover;
  border-radius: 1vw;
  height: 100%;
}
.media-container .aside_content {
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: space-evenly;
}
.media-container .aside_content p,
.media-container .aside_content span {
  color: #2d3745;
  font-size: 14px;
  display: block;
}
.media-container .aside_content h3 {
  font-size: 27px;
  font-family: "Poppins";
  font-weight: 500;
  color: #2d3745;
  line-height: 29px;
  text-align: left;
  margin: 2% 0;
}
.media-container .aside_content button.green_btn {
  width: 160px;
  margin: 0;
  justify-content: space-around;
}

.media-container button.green_btn {
  background: #41ac3e;
  width: 100%;
  margin: 4% auto 0 auto;
  padding: 1% 2%;
  height: 7vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: none;
  border-radius: 0.5vw;
  cursor: pointer;
}
.media-container .bottom_blogs {
  margin-top: 2%;
  padding-top: 0%;
  border-top: none;
}
.media-container .bottom_blogs .inner_wrapper {
  padding: 15px;
}
.media-container .bottom_blogs img {
  height: 15vh;
}
.media-container .bottom_blogs img {
  width: 100%;
  margin-bottom: 2%;
  height: 28vh;
  object-fit: cover;
  border-radius: 1vw;
}
.media-container .bottom_blogs p,
.media-container .bottom_blogs span {
  color: #2d3745;
  font-size: 13px;
  display: block;
}
.media-container .bottom_blogs h3 {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
  color: #2d3745;
  line-height: 22px;
  text-align: left;
  margin: 2% 0;
}
