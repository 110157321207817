.pg-funding section.ev-Banner_sec {
  height: 50vh !important;
}
.funding-content {
  padding: 50px 0px;
}
.fund-bg-head h5 {
  font-family: "Poppins";
  margin: 0px 10px;
  font-size: 22px;
  text-align: left;
}
/* Search */
.fund-bg-search label {
  background-color: #f1f5f9;
  /* padding: 5px; */
  display: flex;
  justify-content: space-between;
  border-radius: 7px;
}
.fund-bg-search label input {
  padding: 0px 10px;
  font-size: 14px;
}
.fund-bg-search label .search-icon {
  color: #fff;
  background-color: #41ac3e;
  padding: 7px;
  border-radius: 7px;
  font-size: 20px;
  cursor: pointer;
}
