.relatedPost {
  padding: 50px 0px;
}
.relatedPost h4 {
  font-family: "Poppins";
}
.related-item {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid #dddddd;
  padding: 10px 0px;
}
/* .related-item .related-img {
  width: 50px;
  height: 50px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
} */
.related-item .related-img img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
}

.related-item .related-content {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.related-item .related-content p {
  margin-bottom: 0px;
}
.related-item .related-content .sub-text {
  /* padding: 5px 0px; */
  font-size: 12px;
  color: #41ac3e;
}
.related-item .related-content .blog-head {
  /* background-color: #b6d5ce; */
  font-size: 16px;
  padding: 0px 0px;
  font-family: "Poppins";
  text-align: left;
}
