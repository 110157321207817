.tabs_section {
  display: flex;
  flex-flow: column;
  margin: 2%;
}

.tabs_section ul.tabs {
  margin: 0;
  padding: 0;
  float: left;
  list-style: none;
  height: 36px;
  width: 100%;
  display: flex;
}

.tabs_section ul.tabs li {
  float: left;
  margin: 0;
  cursor: pointer;
  padding: 0px 24px 0px 15px;
  height: 100%;
  background-color: #e4e4e7;
  color: #a8a8a8;
  overflow: hidden;
  position: relative;
  border-top-right-radius: 10px;
  margin-right: 1%;
  border-top-left-radius: 5px;
  clip-path: polygon(0 0, 90% 0, 100% 100%, 0 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}

.tabs_section ul.tabs li.active {
  background-color: #fff;
  color: #41ac3e;
  display: flex;
}

.tabs_section .tab_container {
  border-top: none;
  clear: both;
  float: left;
  width: 100%;
  background: #fff;
  overflow: auto;
  padding: 0;
  height: fit-content;
  margin: 0;
  border-radius: 1vw;
  border-top-left-radius: 0;
}

.tabs_section .tab_content {
  padding: 20px;
  /* display: none; */
}
section.board {
  background: #f3f4fb;
}
.tabs_section .tab_drawer_heading {
  /* display: none; */
}

.form_sec .form_group label {
  color: #969da6;
  background: none;
  width: 100%;
  justify-content: flex-start;
  height: auto;
  font-size: 13px;
  margin-bottom: 2%;
}

.form_sec .form_group input {
  display: block;
  padding: 5px 15px;
  width: 100%;
  box-sizing: border-box;
  background-color: #f4f5fc;
  border: none;
  height: 40px;
  border-radius: 5px;
  outline: none;
  color: #5d5e62;
  font-weight: 400;
}
.form_sec .form_group input.hasDatepicker {
  padding: 5px 15px 5px 35px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23424242" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: 12px;
}
.form_sec .form_group select {
  display: block;
  padding: 5px 28px 5px 15px;
  width: 100%;
  font-weight: 400;
  color: #5d5e62;
  box-sizing: border-box;
  background-color: #f4f5fc;
  border: none;
  height: 40px;
  border-radius: 5px;
  outline: none;
  background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-size: 8px;
  background-position: 93%;
  background-repeat: no-repeat;
}
.form_sec .form_group {
  margin: 3% 6%;
  font-size: 13px;
}
.tabs_section .tab_container h4 {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 500;
  color: #4f555c;
  display: flex;
  align-items: center;
  margin-bottom: 1%;
  line-height: 22px;
}
.tabs_section .tab_container h4 img {
  max-width: 22px;
  max-height: 22px;
  margin-right: 10px;
}

.form_sec .form_group label span {
  margin-left: 5px;
  color: red;
}

.flip-card-3D-wrapper {
  width: 100%;
  height: 100%;
  -o-perspective: 900px;
  -webkit-perspective: 900px;
  -ms-perspective: 900px;
  perspective: 900px;
  display: flex;
}
#flip-card {
  width: 100%;
  height: auto;
  text-align: center;
  -o-transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
  -ms-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  -o-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  margin: 0 auto;
}
.do-flip {
  -o-transform: rotateY(-180deg);
  -webkit-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
#flip-card-btn-turn-to-back,
#flip-card-btn-turn-to-front {
  width: 100%;
  height: 40px;
  background: white;
  cursor: pointer;
  /* visibility: hidden; */
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 0.7em;
  padding: 0;
  color: grey;
  border: 1px solid grey;
}
#flip-card .flip-card-front,
#flip-card .flip-card-back {
  width: 100%;
  -o-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 2;
  padding: 20px 0px;
}
#flip-card .flip-card-front,
#flip-card .flip-card-back {
  background: #ffff;
  border-radius: 15px;
  margin: 40px 0;
}
#flip-card .flip-card-back {
  background: white;
  -o-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  transform: rotateY(180deg);
  border-radius: 15px;
}
#flip-card .flip-card-back .form_group input {
  background: #f4f5fc;
  height: 34px;
}
#flip-card .flip-card-front p,
#flip-card .flip-card-back p {
  color: grey;
  display: block;
  position: absolute;
  top: 40%;
  width: 100%;
}

.table_sec {
  display: grid;
  overflow: hidden;
  width: 97%;
  margin: 4% auto 0 auto;
}
table.table.table-bordered.table-striped {
  display: flex;
  flex-flow: column;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: scroll;
  margin-top: 1%;
}
table.table.table-bordered.table-striped th {
  /* width: 119px; */
  width: 100%;
  font-weight: 400;
  margin-left: 10px;
  height: 34px;
  color: #969da6;
  background: #f4f5fc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 0;
}

table.table.table-bordered.table-striped td {
  /* width: 119px; */
  width: 100%;
  font-weight: 400;
  background: #f4f5fc;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: 10px;
  border-width: 0;
}
table.table.table-bordered.table-striped thead {
  display: grid;
  font-size: 13px;
  font-family: "Poppins";
  line-height: 15px;
  /* height: 34px; */
  color: #969da6;
  background: #f4f5fc;
  padding: 6px;
  border-radius: 6px;
  width: 100%;
}

table.table.table-bordered.table-striped tbody {
  display: grid;
  font-size: 13px;
  font-family: "Poppins";
  line-height: 15px;
  color: #969da6;
  align-content: flex-start;
  /* overflow-y: scroll; */
  /* height: 15vh; */
  width: fit-content;
  padding-bottom: 15px;
}
table.table.table-bordered.table-striped tr {
  display: flex;
  align-items: center;
  border-width: 0;
}
table.table.table-bordered.table-striped tbody tr {
  margin: 8px 0px;
  height: 34px;
}
table.table.table-bordered.table-striped td input {
  background: no-repeat;
  display: block;
  box-sizing: border-box;
  border: none;
  padding: 0 10px;
  font-size: 13px;
  font-weight: 400;
  outline: none;
  width: 100%;
}
table.table.table-bordered.table-striped td select {
  background: no-repeat;
  display: block;
  box-sizing: border-box;
  border: none;
  padding: 0 20px 0px 10px;
  font-size: 13px;
  font-weight: 600;
  outline: none;
  width: 100%;
  outline: none;
  background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-size: 13px;
  font-weight: 400;
  color: #5d5e62;
  background-position: 93%;
}
.avatar-upload {
  position: relative;
  /* padding: 0px 30px 0px 30px; */
  margin: 0 auto;
}
.avatar-upload .avatar-edit {
  position: absolute;
  right: 22%;
  z-index: 1;
  top: -10%;
}
.avatar-upload .avatar-edit input {
  display: none;
}
.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}
.avatar-upload .avatar-edit input + label:after {
  content: "\270F";
  font-family: "FontAwesome";
  color: #757575;
  position: absolute;
  top: 3px;
  left: 0px;
  right: 0px;
  text-align: center;
  margin: auto;
  font-size: 19px;
  transform: rotate(137deg);
}
.avatar-upload h3 {
  font-family: "Poppins";
  font-weight: 400;
  margin-top: 12px;
  font-size: 20px;
}
.avatar-upload .avatar-preview {
  position: relative;
  border-radius: 100%;
  border: 2px solid #e7e8ee;
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 10%);
  width: 120px;
  height: 120px;
  /* margin: 0 auto; */
}
.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

form.preview_card hr {
  width: 80%;
  margin: 0 auto 7% auto;
  opacity: 0.5;
}
form.preview_card .form_group {
  display: flex;
  flex-flow: column;
  width: 100%;
  margin: 0 2%;
  align-items: center;
  align-self: center;
}
form.preview_card .form_group label {
  color: #969da6;
  background: none;
  width: 100%;
  justify-content: flex-start;
  height: auto;
  font-size: 13px;
  margin-bottom: 2%;
}
form.preview_card .form_group input {
  display: block;
  padding: 5px 15px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  height: 30px;
  /* border-radius: 5px; */
  outline: none;
  color: #5d5e62;
  font-weight: 400;
  text-align: center;
  /* border-bottom: 1px solid; */
  border: 0;
  background-color: #f4f5fc;
  border-radius: 5px;
}
form.preview_card .form_group input:read-only {
  /* border: 0; */
  background-color: transparent;
}
.do-flip > .flip-card-front {
  display: none;
}
#flip-card > .flip-card-back {
  display: none;
}
.do-flip > .flip-card-back {
  display: block !important;
}

form.business_plan .form_group label {
  color: #969da6;
  background: none;
  width: 100%;
  justify-content: flex-start;
  height: auto;
  font-size: 13px;
  margin: 0.5% 0 0.5% 0;
  text-align: left;
  line-height: 16px;
}
.form_group textarea {
  background: #f4f5fc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 15px;
  height: 40px;
  border: 1px solid #41ac3eb0;
  border-radius: 5px;
  font-size: 13px;
  font-family: "Poppins";
  color: #5d5e62;
  outline: none;
}
.form_group textarea::placeholder {
  color: #969696;
  font-size: 12px;
}
form.business_plan .form_group {
  margin: 3% 0;
  display: grid;
}

form.business_plan .form_group .inner {
  margin-bottom: 1%;
}

form.business_plan .form_group ul {
  width: 90%;
  margin: 1% auto;
}
form.business_plan .form_group ul li {
  display: flex;
  margin: 1% 0;
}
form.business_plan .form_group ul li label {
  display: flex;
  width: 60%;
  margin-right: 5%;
}
form.business_plan .form_group ul li input {
  display: block;
  padding: 5px 15px;
  width: 40%;
  box-sizing: border-box;
  background-color: #f4f5fc;
  border: none;
  height: 40px;
  border-radius: 5px;
  outline: none;
  color: #5d5e62;
  font-weight: 400;
}
form.business_plan .form_group ul li:nth-child(2) input:placeholder {
  width: 150px;
  height: 40px;
  background: red;
  display: block;
}

.form_group > table.table.table-bordered.table-striped tbody tr td:first-child {
  background: #fff;
  /* width: 300px; */
  width: 150%;

  border-width: 0;
}
.form_group > table.table.table-bordered.table-striped thead tr th:first-child {
  /* width: 300px; */
  width: 150%;
}
.form_group > table.table.table-bordered.table-striped tbody tr {
  height: fit-content;
  margin: 8px 0px 0px 8px;
}
.form_group > table.table.table-bordered.table-striped tbody {
  height: fit-content;
}
.form_group > table.table.table-bordered.table-striped tbody input {
  height: 34px;
  border-radius: 10px;
}
.form_group > table.table.table-bordered.table-striped td,
.form_group > table.table.table-bordered.table-striped th {
  margin: 0 5px;
  width: 100%;
}
#last ul li input {
  width: 20%;
  margin: 0 0.5%;
}

.plans_view h2 {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 500;
  color: #4f555c;
  margin: 0 auto 1% auto;
  line-height: 22px;
  text-align: center;
  width: 65%;
  border-bottom: 1px solid rgb(0 0 0 / 16%);
  padding-bottom: 1%;
}
.plans_view ul {
  display: flex;
  margin-top: 5%;
}
.plans_view ul li {
  width: 50%;
  display: flex;
}
.plans_view ul li .plan-side {
  margin: 0 2%;
  padding: 5%;
  background: #ecf9e7;
  border-radius: 0px 38px 0px 38px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.plans_view ul li .plan-side > ul li {
  width: 100%;
  font-size: 13px;
  line-height: 16px;
  padding: 0 0 2% 0%;
}
.plans_view ul li .plan-side > ul {
  flex-flow: column;
  margin: 6% 0;
}
.plans_view ul li .plan-side h3 {
  font-size: 32px;
  line-height: 30px;
  font-family: "Poppins";
  font-weight: 500;
  color: #454545;
  margin-top: 1%;
}
.plans_view ul li .plan-side h5 {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 300;
  color: #4f555c;
  margin: 0 auto 1% 0;
  line-height: 22px;
  text-align: left;
}
.plans_view ul li .plan-side > ul li img {
  width: 14px;
  margin-right: 10px;
}
.plans_view ul li .plan-side button.green_btn {
  width: 90%;
}

select.green_select {
  background-color: #41ac3e;
  border: none;
  color: #fff;
  width: 10vw;
  height: 5vh;
  border-radius: 0.5vw;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 400;
  padding: 2px 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23fff%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A);
  background-repeat: no-repeat;
  background-size: 7px;
  background-position: 91%;
  outline: none;
}

/* Dashboard Tabs */
.board {
  padding: 50px 0px;
}

.dashboard-tabs ul li button {
  float: left;
  margin: 0;
  cursor: pointer;
  padding: 10px 24px 10px 15px;
  height: 100%;
  width: 100%;
  background-color: #e4e4e7;
  color: #a8a8a8;
  overflow: hidden;
  position: relative;
  border-top-right-radius: 10px;
  margin-right: 1%;
  border-top-left-radius: 5px;
  clip-path: polygon(0 0, 90% 0, 100% 100%, 0 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  border: 0 !important;
}
.dashboard-tabs ul li button.active {
  background-color: #fff !important;
  color: #41ac3e !important;
  display: flex;
}
.dashboard-head {
  font-size: 24px;
  color: #000;
  font-family: "Poppins";
  font-weight: 900;
}
.dashboard-tabs .tab_container {
  border-top: none;
  clear: both;
  float: left;
  width: 100%;
  background: #fff;
  overflow: auto;
  padding: 0;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;
  border-radius: 1vw;
  border-top-left-radius: 0;
}
.dashboard-tabs .tab_container .tab_content {
  padding: 20px;
}
.dashboard-tabs .tab_container .tab_content h4 {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 500;
  color: #4f555c;
  display: flex;
  align-items: center;
  margin-bottom: 1%;
  line-height: 22px;
}
.dashboard-tabs .tab_container .tab_content h4 img {
  max-width: 22px;
  max-height: 22px;
  margin-right: 10px;
}

.dashboard-tabs .head-cto {
  background: #41ac3e;
  border: none;
  color: #fff;
  width: 10vw;
  height: 5vh;
  border-radius: 0.5vw;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 400;
}
.testingtable {
  width: 100%;
  overflow-x: scroll;
}
.testingtable th {
  width: 20%;
}
/* Table scroll
.table-bordered::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
.table-bordered::-webkit-scrollbar-thumb {
  background: #41ac3e;
  margin-top: 10px;
} */
.add-coreteams {
  margin: 0px 10px;
  border: 1px solid #41ac3e;
  border-radius: 100%;
  height: 25px;
  width: 25px;
}
.add-coreteams svg {
  margin-top: 3px;
}

/* Mobile View */
@media screen and (max-width: 480px) {
  .dashboard-account .submit-app-btn {
    width: 15vh !important;
    height: 4vh !important;
  }
  .dashboard-account table th {
    width: 15%;
  }
  .avatar-upload .avatar-preview {
    margin: 0 auto;
  }
  form.preview_card .form_group {
    margin-top: 20px;
  }
  #flip-card-btn-turn-to-back {
    margin-top: 20px;
    width: 50%;
  }
  .dashboard-tabs ul li button {
    padding: 10px 15px 10px 8px;
  }
  .table-bordered::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }
  .table-bordered::-webkit-scrollbar-thumb {
    background: #41ac3e;
    margin-top: 10px;
  }
  table.table.table-bordered.table-striped td {
    width: 150px !important;
  }
  table.table.table-bordered.table-striped th {
    width: 150px !important;
    height: auto;
  }
  table.table.table-bordered.table-striped thead {
    width: fit-content;
  }
  /* .form_group
    > table.table.table-bordered.table-striped
    thead
    tr
    th:first-child {
    width: 400px !important;
  } */
  /* .form_group
    > table.table.table-bordered.table-striped
    tbody
    tr
    td:first-child {
    width: 400px !important;
  } */
  .form_group > table.table.table-bordered.table-striped tbody tr {
    width: 100%;
  }
  .dashboard-tabs .head-cto {
    width: 40%;
    height: 4vh;
    font-size: 14px;
    margin: 0 30%;
  }
  /* Tab 3 */
  .dashboard-tabs .tab_container .tab_content {
    padding: 40px 20px;
  }
  .plans_view ul {
    display: flex;
    margin-top: 10%;
    flex-wrap: wrap;
  }
  .plans_view ul li {
    width: 100%;
    display: flex;
  }
  .plans_view ul li .plan-side {
    margin: 2%;
    padding: 5%;
    background: #ecf9e7;
    border-radius: 0px 38px 0px 38px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }
  .plans_view ul li .plan-side button.green_btn {
    width: 90%;
    margin-left: 10%;
  }
}

/* Dashboard Account */
.dashboard-account {
  background-color: #fff;
  padding: 50px 0;
  border-radius: 15px;
  margin: 40px 0;
  font-family: "Poppins";
}
.dashboard-account h3 {
  font-family: "Poppins";
  font-weight: 500;
}
.dashboard-account table {
  width: 100%;
}
.dashboard-account table th {
  border-bottom: 1px solid #000;
}
.dashboard-account .submit-app-btn {
  background: #41ac3e;
  border: none;
  color: #fff;
  width: 10vw;
  height: 5vh;
  border-radius: 0.5vw;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 400;
}
